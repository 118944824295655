import * as React from 'react'
import styled from 'styled-components'
import Spinner from 'react-loader-spinner'

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import theme from '@theme/Theme'

const Loader = () => {
  return (
    <MainContainer>
      <Spinner type="Bars" color={theme.colors.white} height={50} width={50} />
    </MainContainer>
  )
}

export default Loader

const MainContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.primary};
`
