const ENV: Env = (global as any).ENV || 'release'

const EnvConfig = (env: Env): EnvConfig => {
  switch (env) {
    case 'dev':
      return {
        logLevel: 'debug',
        VISIOGLOBE_HASH: 'dev-ke33d598fa966cec7e282f1c35785713b2f44bc69',
        WSS_PREFIX: 'wss://aesio.int.cardiweb.com/realtime',
      }
    case 're7':
      return {
        logLevel: 'debug',
        VISIOGLOBE_HASH: 'dev-ke33d598fa966cec7e282f1c35785713b2f44bc69',
        WSS_PREFIX: 'wss://aesio.cardicloud.com/realtime',
      }
    case 'release':
      return {
        logLevel: 'info',
        VISIOGLOBE_HASH: 'k179ab3e7c2a07647933e14170c9b1b78e2ee118b',
      }
    default: {
      throw Error('Unknown env : ' + ENV)
    }
  }
}

const config: Config = {
  ENV,
  ...EnvConfig(ENV),
}

const load = (env: Env): void => {
  Object.assign(config, { ENV: env })
  Object.assign(config, EnvConfig(env))
}

export default config
export { load }
