import * as React from 'react'
import { createVisioMap } from 'react-visioglobe'
import useWebSocket from 'react-use-websocket'
import styled from 'styled-components'
import Loader from './Loader'
import FloorSelector from './FloorSelector'
import config from '@app/config'

const getMapView = (View: any, myRef: any) => {
  return View ? <View ref={myRef} /> : null
}

const MapScreen = () => {
  const myRef: React.RefObject<VisioMapViewRef> = React.createRef()
  const [View, setView] = React.useState()
  const [ref, setRef] = React.useState<React.RefObject<VisioMapViewRef>>({ ...myRef })
  const [refInit, setRefInit] = React.useState(false)
  const [init, setInit] = React.useState(false)
  const [loaded, setLoaded] = React.useState(false)
  const [, setInitMultibuilding] = React.useState(false)
  const [occupancies, setOccupancies] = React.useState<OccupancyStatus[]>([])

  const props = {
    mapParam: {
      logoPosition: 'TOP_RIGHT',
      path: `https://mapserver.visioglobe.com/${config.VISIOGLOBE_HASH}/descriptor.json`,
      antialias: true,
      cameraType: 'perspective',
    },
    multiParam: {
      viewType: 'multibuilding',
      animationType: 'translation',
      container: '',
    },
  }

  React.useEffect(() => {
    createVisioMap().then((m: any) => {
      setView(m.createView({ ...props }))
    })
  }, [])

  React.useEffect(() => {
    if (myRef.current !== null && !refInit) {
      setRefInit(true)
      setRef({ ...myRef })
    }
  }, [myRef])

  React.useEffect(() => {
    if (refInit && ref && ref.current) {
      const onObjectMouseUp: ((e: any, el: HTMLElement) => void) | null = (e: any, el: HTMLElement) => null
      const initFct: (value: boolean) => void = (value: boolean) => setInit(value)
      const initMutliFct: (value: boolean) => void = (value: boolean) => setInitMultibuilding(value)
      ref.current.initVisioglobe(onObjectMouseUp, initFct, initMutliFct)
    }
  }, [refInit])

  React.useEffect(() => {
    if (init) {
      setLoaded(true)
    }
  }, [init])

  const websocketStaticOptions = React.useMemo(
    () => ({ shouldReconnect: (closeEvent: WebSocketEventMap['close']) => true }),
    []
  )

  const { lastMessage } = useWebSocket(config.WSS_PREFIX || '', websocketStaticOptions)

  React.useEffect(() => {
    if (lastMessage) {
      const data = JSON.parse(lastMessage.data)
      if (data.type === 'occupancy') {
        setOccupancies(data.data)
      }
    }
  }, [lastMessage])

  React.useEffect(() => {
    if (init && ref && ref.current && ref.current.colorPlaces) {
      ref.current.colorPlaces(
        occupancies.map((poi: OccupancyStatus) => ({
          id: poi.visioglobeId,
          color: poi.color || '#FFF',
        }))
      )
    }
  }, [init, occupancies])

  return (
    <MainContainer>
      {getMapView(View, myRef)}
      {init && <FloorSelector visiomapRef={ref.current} />}
      {!loaded && <Loader />}
    </MainContainer>
  )
}

export default MapScreen

const MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
