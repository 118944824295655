import * as React from 'react'
import styled from 'styled-components'
import Selector from './Selector'

interface Props {
  visiomapRef: any
}

const FloorSelector = ({ visiomapRef }: Props) => {
  const [buildings, setBuildings] = React.useState([])
  const [building, setBuilding] = React.useState<any | undefined>()
  const [floor, setFloor] = React.useState<string>()

  React.useEffect(() => {
    if (visiomapRef) {
      setBuildings(visiomapRef.getBuildings())
    }
  }, [visiomapRef])

  React.useEffect(() => {
    if (building) {
      visiomapRef.goToBuilding(building.id)
      setFloor(building.floors[building.defaultFloorIndex].id)
    }
  }, [building])

  React.useEffect(() => {
    if (floor) {
      visiomapRef.goToFloor(floor)
    }
  }, [floor])

  return (
    <MainContainer>
      <Selector
        value={building ? building.id : undefined}
        data={[...buildings].sort((a: any, b: any) =>
          a.displayIndex > b.displayIndex ? -1 : a.displayIndex < b.displayIndex ? 1 : 0
        )}
        label={building ? visiomapRef.getName(building.id) : 'Bâtiment'}
        renderItem={(item) => visiomapRef.getName(item.id)}
        onSelect={(item) => setBuilding(item)}
      />
      {building && (
        <Selector
          value={floor}
          data={[...building.floors].sort((a: any, b: any) =>
            a.levelIndex > b.levelIndex ? -1 : a.levelIndex < b.levelIndex ? 1 : 0
          )}
          label={floor ? visiomapRef.getName(floor) : 'Etage'}
          renderItem={(item) => visiomapRef.getName(item.id)}
          onSelect={(item) => setFloor(item.id)}
        />
      )}
    </MainContainer>
  )
}

export default FloorSelector

const MainContainer = styled.div`
  position: absolute;
  bottom: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`
