import * as React from 'react'
import MapScreen from '@screens/map/MapScreen'
import { ThemeProvider } from 'styled-components'
import theme from '@theme/Theme'

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <MapScreen />
    </ThemeProvider>
  )
}

export default App
