import * as React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'

interface Props {
  data: any[]
  renderItem: (item: any) => React.ReactNode
  label: string
  onSelect: (item: any) => void
  value?: string
}

const Selector = ({ data, renderItem, label, onSelect, value }: Props) => {
  const [open, setOpen] = React.useState(false)

  React.useEffect(() => {
    setOpen(false)
  }, [value])

  return (
    <SelectorContainer>
      <DropdownContainer open={open} count={data.length}>
        {data.map((item, id) => (
          <DropdownItem selected={value === item.id} key={id} onClick={() => onSelect(item)}>
            {renderItem(item)}
          </DropdownItem>
        ))}
      </DropdownContainer>
      <SelectedContainer onClick={() => setOpen(!open)}>
        {label}
        <Icon icon={open ? faChevronDown : faChevronUp} />
      </SelectedContainer>
    </SelectorContainer>
  )
}

export default Selector

const SelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 4px;
`

const SelectedContainer = styled.div`
  background-color: ${(props) => props.theme.colors.primary};
  ${(props) => props.theme.fonts.selectedFloor}
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 56px;
  padding: 0 10px;
  border-radius: 4px;
  cursor: pointer;
`

const DropdownContainer = styled.ul<{ open: boolean; count: number }>`
  ${(props) => !props.open && 'height: 0;'}
  ${(props) => props.open && `height: ${props.count * 52}px;`}
  background-color: ${(props) => props.theme.colors.white};
  margin: 0;
  margin-bottom: 4px;
  padding: 0;
  list-style: none;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  overflow: hidden;
  transition: height 0.3s ease;
`

const DropdownItem = styled.li<{ selected: boolean }>`
  ${(props) => props.selected && `background-color: ${props.theme.colors.primary};`}
  ${(props) => props.selected && `color: ${props.theme.colors.white};`}
  height: 52px;
  line-height: 52px;
  text-align: center;
  cursor: pointer;
  padding: 0 10px;
`

const Icon = styled(FontAwesomeIcon)`
  margin-left: 8px;
`
