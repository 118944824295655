import colors from '@theme/colors'
import fonts from '@theme/fonts'

const theme: Theme = {
  colors,
  fonts,
  constants: {},
}

export default theme
